import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    jobID: null,
};

export const setJobId = (jobid) => {
    return {
        type: 'jobIdData/setJobIddata',
        payload: jobid
    };
};

// Create a slice to manage the state
const jobIdDataSlice = createSlice({
    name: 'jobIdData',
    initialState,
    reducers: {
        // Reducer to handle setting the room name
        setJobIddata: (state, action) => {
            state.jobID = action.payload;
        },
    },
});

// Export the reducer
export default jobIdDataSlice.reducer;