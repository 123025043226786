import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

// Define the initial state for your slice
const initialState = {
  loading: false,
  errorState:false,
  errorMsg: "",
  data: null,  
};

// Define an async thunk action creator to fetch data via API
export const fetchIncidentAccountData = createAsyncThunk(
  'incidentDataSlice/fetchIncidentAccountData',
  async ({ authenticationToken, startDate, endDate, accountId, referrer }) => {
    try {
      const response = await fetch(`https://${referrer}getIncidentsOfAccount`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `Bearer ${authenticationToken}`,
        },
        body: JSON.stringify({
          account_id: accountId, 
          start_date: startDate,
          end_date: endDate,
        }),
      });

      if (!response.ok) {
        console.log(response);
        // throw new Error('Network response was not ok');
      }

      const data = await response.json();
      return data;
    } catch (error) {
      throw new Error('Error fetching data: ' + error.message);
    }
  }
);

// Create a slice to manage the state
const incidentDataSlice = createSlice({
  name: 'data',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchIncidentAccountData.pending, (state) => {
        state.loading = true;
        state.errorState = false;
        state.errorMsg = "";
      })
      .addCase(fetchIncidentAccountData.fulfilled, (state, action) => {
        state.loading = false;
        state.errorState = false;
        state.errorMsg = "";
        state.data = action.payload;
      })
      .addCase(fetchIncidentAccountData.rejected, (state, action) => {
        state.loading = false;
        state.errorState = true;
        state.errorMsg = action.error.message;
      });
  },
});

// Export the reducer
export default incidentDataSlice.reducer;
