import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Define the initial state for your slice
const initialState = {
  ZoneNameList:null,
  ZonesList:null
};

//TZ-988 -- New API call to fetch all the assets/zones/locations for an account
export const fetchHamiltonZoneNameList = createAsyncThunk(
  'hamiltonZoneListData/fetchHamiltonZoneNameList',
  async ({ authenticationToken, referrer }) => {
    try {
      const response = await fetch(`https://${referrer}getAssetsOfAccount`, {
        method: "GET",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${authenticationToken}`,
        }        
      });

      // if (!response.ok) {
      //   throw new Error('Network response was not ok');
      // }
      return response.json();
    } catch (error) {
        console.log(error)
      throw new Error('Error fetching analytics data: ' + error.message);
    }
  }
);

export const setZoneNameList = (value) => {
  return {
    type: 'hamiltonZoneListData/setZoneNameList',
    payload: value,
  }
}

// Create a slice to manage the state
const hamiltonZoneNamesListlice = createSlice({
  name: 'hamiltonZoneListData',
  initialState,
  reducers: {
    setZoneNameList: (state, action) => {
      state.ZoneNameList = action.payload;
    },
  },
  extraReducers: (builder) => {
    /**TZ-988 */
    builder
      .addCase(fetchHamiltonZoneNameList.pending, (state) => {
        state.loading = true;
        state.errorState = false;
        state.errorMsg = "";
      })
      .addCase(fetchHamiltonZoneNameList.fulfilled, (state, action) => { 
        state.ZonesList = action.payload       
        state.loading = false;
        state.errorState = false;
        state.errorMsg = "";
      })
      .addCase(fetchHamiltonZoneNameList.rejected, (state, action) => {
        state.loading = false;
        state.errorState = true;
        state.errorMsg = action.error.message;
      })
  },
});

export default hamiltonZoneNamesListlice.reducer;