import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Define the initial state for your slice
const initialState = {
  loading: false,
  errorState:false,
  errorMsg: "",
  actionTypeData: null,
};

export const fetchActionTypeList = createAsyncThunk(
  'ActionTypeListDataSlice/fetchActionTypeList',
  async ({ authenticationToken, referrer }) => {
    try {
      const response = await fetch(`https://${referrer}getActionPlanTypes`, {
        method: "GET",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${authenticationToken}`,
        }       
      });

      // if (!response.ok) {
      //   throw new Error('Network response was not ok');
      // }
      return response.json();
    } catch (error) {
        console.log(error)
      throw new Error('Error fetching analytics data: ' + error.message);
    }
  }
);

// Create a slice to manage the state
const ActionTypeListDataSlice = createSlice({
  name: 'fetchActionTypeList',
  initialState,
  reducers: {
   
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchActionTypeList.pending, (state) => {
        state.loading = true;
        state.errorState = false;
        state.errorMsg = "";
      })
      .addCase(fetchActionTypeList.fulfilled, (state, action) => {
        state.loading = false;
        state.errorState = false;
        state.errorMsg = "";
        state.actionTypeData = action.payload;

      })
      .addCase(fetchActionTypeList.rejected, (state, action) => {
        state.loading = false;
        state.errorState = true;
        state.errorMsg = action.error.message;
      })
  },
});

export default ActionTypeListDataSlice.reducer;