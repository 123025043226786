import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Define the initial state for your slice
const initialState = {
  loading: false,
  errorState:false,
  errorMsg: "",
  shiftReportData: null,
  loggedInUsers: null,
  allEmployees: null,
  isAddShiftReportFormOpen: false,
  submitloading: false,
  submitMsg: null,
  submitted: false,
  submitErrorMsg: null,
  refreshAfterHourData: false
};

export const fetchShiftReportData = createAsyncThunk(
  'afterHourReport/fetchShiftReportData',
  async ({ authenticationToken, referrer, accountId, startDate, endDate }) => {
    try {
      const response = await fetch(`https://${referrer}getAfterHourReports`, {
        method: "POST",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${authenticationToken}`,
        },
        body: JSON.stringify({
          account_id: accountId,
          start_date: startDate,
          end_date: endDate,
        })      
      });

      // if (!response.ok) {
      //   throw new Error('Network response was not ok');
      // }
      return response.json();
    } catch (error) {
        console.log(error)
      throw new Error('Error fetching analytics data: ' + error.message);
    }
  }
);

export const fetchUsersLoggedIn = createAsyncThunk(
  'afterHourReport/fetchUsersLoggedIn',
  async ({ authenticationToken, referrer, accountId, startDate }) => {
    try {
      const response = await fetch(`https://${referrer}getUsersloggedIn`, {
        method: "POST",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${authenticationToken}`,
        },
        body: JSON.stringify({
          account_id: accountId,
          from_date: startDate           
        })      
      });

      // if (!response.ok) {
      //   throw new Error('Network response was not ok');
      // }
      return response.json();
    } catch (error) {
        console.log(error)
      throw new Error('Error fetching analytics data: ' + error.message);
    }
  }
);

export const fetchAllUsers = createAsyncThunk(
  'afterHourReport/fetchAllUsers',
  async ({ authenticationToken, referrer }) => {
    try {
      const response = await fetch(`https://${referrer}getAllUsers`, {
        method: "GET",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${authenticationToken}`,
        }     
      });      
      return response.json();
    } catch (error) {
        console.log(error)
      throw new Error('Error fetching analytics data: ' + error.message);
    }
  }
);

export const addShiftReport = createAsyncThunk(
  'afterHourReport/addShiftReport',
  async ({ authenticationToken, referrer, startDate, comment, userShift, incidentData }) => {
    try {
      let json_body = JSON.stringify({
        start_date: startDate,
        comment: comment,
        user_shifts: userShift,
        incident_followups: incidentData
      })
      const response = await fetch(`https://${referrer}addAfterHourReport`, {
        method: "POST",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${authenticationToken}`,
        },
        body: json_body
      });      
      return response.json();
    } catch (error) {
        console.log(error)
      throw new Error('Error fetching analytics data: ' + error.message);
    }
  }
);

export const setAddShiftReportFormStatus = (value) => {
    return {
      type: "afterHourReport/setAddShiftReportFormStatus",
      payload: value,
    };
};

export const setRefreshAfterHourData = (value) => {
    return {
      type: "afterHourReport/setRefreshAfterHourData",
      payload: value,
    }
}

// Create a slice to manage the state
const afterHourReport = createSlice({
    name: 'afterHourReport',
    initialState,
    reducers: {
        // Reducer to handle additional actions related to incidents
        setAddShiftReportFormStatus: (state, action) => {
            state.isAddShiftReportFormOpen = action.payload;
        },
        setRefreshAfterHourData: (state, action) => {
            state.refreshAfterHourData = action.payload;
            if (action.payload === false) {
              state.submitted = false;
            }
        }
    },
    extraReducers: (builder) => {
      builder
        .addCase(fetchShiftReportData.pending, (state) => {
          state.loading = true;
          state.errorStatus = false;
          state.errorMsg = "";
        })
        .addCase(fetchShiftReportData.fulfilled, (state, action) => {
          state.loading = false;
          state.errorStatus = false;
          state.errorMsg = "";
          state.shiftReportData = action.payload;
        })
        .addCase(fetchShiftReportData.rejected, (state, action) => {
          state.loading = false;
          state.errorStatus = true;
          state.errorMsg = action.payload;
          state.shiftReportData = null;
        })
        .addCase(fetchAllUsers.pending, (state) => {
          state.allEmployees = null;
        })
        .addCase(fetchAllUsers.fulfilled, (state, action) => {
          state.allEmployees = action.payload;
        })
        .addCase(fetchAllUsers.rejected, (state) => {
          state.allEmployees = null;
        })
        .addCase(fetchUsersLoggedIn.pending, (state) => {
          state.loggedInUsers = null;
        })
        .addCase(fetchUsersLoggedIn.fulfilled, (state, action) => {
          state.loggedInUsers = action.payload;
        })
        .addCase(fetchUsersLoggedIn.rejected, (state) => {
          state.loggedInUsers = null;
        })
        .addCase(addShiftReport.pending, (state) => {
          state.submitloading = true;
        })
        .addCase(addShiftReport.fulfilled, (state, action) => {
          state.submitloading = false;
          state.submitMsg = action.payload.message;
          state.submitted = true;
        })
        .addCase(addShiftReport.rejected, (state, action) => {
          state.submitloading = false;
          state.submitErrorMsg = action.payload.message;
          state.submitted = false;
        })
    },
});
  
// Export the reducer
export default afterHourReport.reducer;