import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

// Define the initial state for your slice
const initialState = {
  loading: false,
  errorState:false,
  errorMsg: "",
  incidentIdData: null,  
  loaded: false //TZ-902
};

// Define an async thunk action creator to fetch data via API
export const fetchIncidentWithIncidentId = createAsyncThunk(
  'SingleIncidentDataSlice/fetchIncidentWithIncidentId',
  async ({ authenticationToken, accountId, startDate, endDate, referrer, incidentId }) => {
    try {
      const response = await fetch(`https://${referrer}getIncidentsOfAccount`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `Bearer ${authenticationToken}`,
        },
        body: JSON.stringify({
          account_id: accountId, 
          start_date: startDate,
          end_date: endDate,
          incident_id: incidentId,
        }),
      });

      if (!response.ok) {
        console.log(response);
        // throw new Error('Network response was not ok');
      }

      const data = await response.json();
      return data;
    } catch (error) {
      throw new Error('Error fetching data: ' + error.message);
    }
  }
);

// Create a slice to manage the state
const SingleIncidentDataSlice = createSlice({
  name: 'data',
  initialState,
  reducers: {
    //TZ-902
    setIncidentIdDataLoaded: (state, action) => {
      state.loaded = action.payload;      
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchIncidentWithIncidentId.pending, (state) => {
        state.loading = true;
        state.loaded = false; //TZ-902
        state.incidentIdData = null;
        state.errorState = false;
        state.errorMsg = "";
      })
      .addCase(fetchIncidentWithIncidentId.fulfilled, (state, action) => {
        state.loading = false;
        state.loaded = true; //TZ-902
        state.errorState = false;
        state.errorMsg = "";
        state.incidentIdData = action.payload;
      })
      .addCase(fetchIncidentWithIncidentId.rejected, (state, action) => {
        state.loading = false;
        state.loaded = false; //TZ-902
        state.errorState = true;
        state.errorMsg = action.error.message;
      });
  },
});

//TZ-902
export const setIncidentIdDataLoaded = (value) => {
  return {
    type: 'data/setIncidentIdDataLoaded',
    payload: value
  };
};

// Export the reducer
export default SingleIncidentDataSlice.reducer;