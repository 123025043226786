import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Define the initial state for your slice
const initialState = {
    emailIncidentLoader: false,
    errorStatus:false,
    emailIncidentError: null,
    emailIncidentData: null,
};

// Define an async thunk action creator to trigger incident report via email
export const emailIncidentReport = createAsyncThunk(
  'emailIncidentSlice/emailIncidentReport',
  async (receivedParameters) => {
  
    try {
      const response = await fetch(`https://${receivedParameters.referrer}sendEmail`, {
      // const response = await fetch('https://analytics.telepresenz.com/analytics/sendEmail', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
            'Accept': 'application/json',
            "Authorization": `Bearer ${receivedParameters.authenticationToken}`,
        },
        body: JSON.stringify(receivedParameters.emailIncidentData),
      });

      if (!response.ok) {
        console.log(response);
        // throw new Error('Network response was not ok');
      }

      const data = await response.json();
      return data;
    } catch (error) {
      throw new Error('Error sending incident report email: ' + error.message);
    }
  }
);

//TZ-1103
export const setEmailDataLoaded = (value) => {
  return {
    type: "emailIncidentSlice/setEmailDataLoaded",
    payload: value,
  };
};

// Create a slice to manage the state
const emailIncidentSlice = createSlice({
    name: 'emailIncidentSlice',
    initialState,
    reducers: {
      // Reducer to handle additional actions related to email incident
      //TZ-1103
      setEmailDataLoaded: (state, action) => {
        state.emailIncidentLoader = action.payload.loader;      
        state.errorStatus = action.payload.errorStatus;
        state.emailIncidentError = action.payload.emailError;
        state.emailIncidentData = action.payload.emailData;
      },
    },
    extraReducers: (builder) => {
      builder
        .addCase(emailIncidentReport.pending, (state) => {
          state.emailIncidentLoader = true;
          state.errorStatus = false;
          state.emailIncidentError = "";
        })
        .addCase(emailIncidentReport.fulfilled, (state, action) => {
          state.emailIncidentLoader = false;
          state.errorStatus = false;
          state.emailIncidentError = "";
          state.emailIncidentData = action.payload;
        })
        .addCase(emailIncidentReport.rejected, (state, action) => {
          state.emailIncidentLoader = false;
          state.errorStatus = true;
          state.emailIncidentError = action.payload;
        })
    },
});

// Export the reducer
export default emailIncidentSlice.reducer;
