import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Define the initial state for your slice
const initialState = {
  editIncidentData: null,
  editIncidentError: null,
};

// Define an async thunk action creator to edit incident data via API
export const editIncident = createAsyncThunk(
  'incidentSlice/editIncident',
  async (receivedParameters) => {
  
    try {
      const response = await fetch(`https://${receivedParameters.referrer}editIncident`, {
      // const response = await fetch('https://analytics.telepresenz.com/analytics/editIncident', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
            'Accept': 'application/json',
            "Authorization": `Bearer ${receivedParameters.authenticationToken}`,
        },
        body: JSON.stringify(receivedParameters.incidentUpdatedData),
      });

      if (!response.ok) {
        console.log(response);
        // throw new Error('Network response was not ok');
      }

      const data = await response.json();
      return data;
    } catch (error) {
      throw new Error('Error editing incident: ' + error.message);
    }
  }
);

// Create a slice to manage the state
const incidentSlice = createSlice({
  name: 'incident',
  initialState,
  reducers: {
    // Reducer to handle additional actions related to incidents
  },
  extraReducers: (builder) => {
    builder
      .addCase(editIncident.pending, (state) => {
        state.editIncidentError = null;
      })
      .addCase(editIncident.fulfilled, (state, action) => {
        state.editIncidentData = action.payload;
      })
      .addCase(editIncident.rejected, (state, action) => {
        state.editIncidentError = action.error.message;
      })
  },
});

// Export the reducer
export default incidentSlice.reducer;
