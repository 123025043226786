import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Define the initial state for your slice
const initialState = {
  loading:false,
  errorStatus:false,
  errorMsg:"",
  addIncidentData: null,
};

// Define an async thunk action creator to edit incident data via API
export const addIncident = createAsyncThunk(
  'addIncidentSlice/addIncident',
  async (receivedParameters) => {
    try {
      const response = await fetch(`https://${receivedParameters.referrer}addIncident`, {
      // const response = await fetch('https://analytics.telepresenz.com/analytics/addIncident', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
            'Accept': 'application/json',
            "Authorization": `Bearer ${receivedParameters.authenticationToken}`,
        },
        body: JSON.stringify(receivedParameters.addIncidentData),
      });

      if (!response.ok) {
        const errorMessage = await response.text();
        // throw new Error(errorMessage || 'Network response was not ok');
      }

      const data = await response.json();
      return data;
    } catch (error) {
      throw new Error('Error adding incident: ' + error.message);
    }
  }
);

// Create a slice to manage the state
const addIncidentSlice = createSlice({
  name: 'addIncident',
  initialState,
  reducers: {
    // Reducer to handle additional actions related to incidents
  },
  extraReducers: (builder) => {
    builder
      .addCase(addIncident.pending, (state) => {
        state.loading = true;
        state.errorStatus = false;
        state.errorMsg = "";
      })
      .addCase(addIncident.fulfilled, (state, action) => {
        state.loading = false;
        state.errorStatus = false;
        state.errorMsg = "";
        state.addIncidentData = action.payload;
      })
      .addCase(addIncident.rejected, (state, action) => {
        state.loading = false;
        state.errorStatus = true;
        state.errorMsg = "action.error.message";
      })
  },
});

// Export the reducer
export default addIncidentSlice.reducer;
