import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Define the initial state for your slice
const initialState = {
  signOutData: null,
  signOutError: null,
};

// Define an async thunk action creator to sign out via API
export const signOut = createAsyncThunk(
  'auth/signOut',
  async (authenticationToken) => {
    try {
      const response = await fetch('https://dev.telepresenzdemo.com/analytics/signout', {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `Bearer ${authenticationToken}`,
        },
      });

      if (!response.ok) {
        console.log(response);
        // throw new Error('Network response was not ok');
      }

      const data = await response.json();
      return data;
    } catch (error) {
      throw new Error('Error signing out: ' + error.message);
    }
  }
);

// Create a slice to manage the state
const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    // Reducers for other auth-related actions can be added here if needed
  },
  extraReducers: (builder) => {
    builder
      .addCase(signOut.pending, (state) => {
        state.signOutError = null;
      })
      .addCase(signOut.fulfilled, (state, action) => {
        state.signOutData = action.payload;
         // Clear session storage post success
         sessionStorage.clear();
          // Close the tab
        window.close();
      })
      .addCase(signOut.rejected, (state, action) => {
        state.signOutError = action.error.message;
      });
  },
});

// Export the reducer
export default authSlice.reducer;
