import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Define the initial state for your slice
const initialState = {
  loading: false,
  errorState: false,
  errorMsg: "",
};

export const fetchAddAction = createAsyncThunk(
  'AddActionDataSlice/fetchAddAction',
  async ({ authenticationToken, referrer, description, account_id, start_date, end_date, technician_id, asset_id, action_plan_type_id, incident_id, location_details }) => {
    try {
      let json = {};
      if (location_details !== undefined) {
        json = {
          description: description,
          account_id: account_id,
          start_date: start_date,
          end_date: end_date,
          technician_id: technician_id,
          asset_id: asset_id,
          action_plan_type_id: action_plan_type_id,
          incident_id: incident_id,
          location_details: location_details
        }
      } else {
        json = {
          description: description,
          account_id: account_id,
          start_date: start_date,
          end_date: end_date,
          technician_id: technician_id,
          asset_id: asset_id,
          action_plan_type_id: action_plan_type_id,
          incident_id: incident_id
        }
      }
      const response = await fetch(`https://${referrer}addActionPlan`, {
        method: "POST",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${authenticationToken}`,
        },
        body: JSON.stringify(json)
      });

      // if (!response.ok) {
      //   throw new Error('Network response was not ok');
      // }
      return response.json();
    } catch (error) {
      console.log(error)
      throw new Error('Error fetching analytics data: ' + error.message);
    }
  }
);

// Create a slice to manage the state
const AddActionDataSlice = createSlice({
  name: 'fetchAddAction',
  initialState,
  reducers: {

  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAddAction.pending, (state) => {
        state.loading = true;
        state.errorState = false;
        state.errorMsg = "";
      })
      .addCase(fetchAddAction.fulfilled, (state, action) => {
        state.loading = false;
        state.errorState = false;
        state.errorMsg = "";
        state.actionTypeData = action.payload;

      })
      .addCase(fetchAddAction.rejected, (state, action) => {
        state.loading = false;
        state.errorState = true;
        state.errorMsg = action.error.message;
      })
  },
});

export default AddActionDataSlice.reducer;