import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Define the initial state for your slice
const initialState = {
  loading: false,
  errorState: false,
  errorMsg: "",
  actionTabData:null,
};

export const fetchActionPlanJobsData = createAsyncThunk(
  'ActionPlanJobsDataSlice/fetchActionPlanJobsData',
  async ({ authenticationToken, referrer, account_id, start_date, end_date, incident_id }) => {
    try {
      let json_body = {}
      //TZ-871 -- To handle the data range selector of Action Plans
      if (incident_id === undefined && start_date === undefined && end_date === undefined) {
        json_body = JSON.stringify({
          account_id: account_id,
          // start_date: start_date,
          // end_date: end_date,
        })
      } else if (incident_id === undefined && start_date !== undefined && end_date !== undefined) {
        json_body = JSON.stringify({
          account_id: account_id,
          start_date: start_date,
          end_date: end_date
        })
      } else {
        json_body = JSON.stringify({
          account_id: account_id,
          // start_date: start_date,
          // end_date: end_date,
          incident_id: incident_id
        })
      }
      const response = await fetch(`https://${referrer}getActionPlanJobs`, {
        method: "POST",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${authenticationToken}`,
        },
        body: json_body
      });

      // if (!response.ok) {
      //   throw new Error('Network response was not ok');
      // }
      return response.json();
    } catch (error) {
      console.log(error)
      throw new Error('Error fetching analytics data: ' + error.message);
    }
  }
);

// Create a slice to manage the state
const ActionPlanJobsDataSlice = createSlice({
  name: 'fetchActionPlanJobsData',
  initialState,
  reducers: {

  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchActionPlanJobsData.pending, (state) => {
        state.loading = true;
        state.errorState = false;
        state.errorMsg = "";
      })
      .addCase(fetchActionPlanJobsData.fulfilled, (state, action) => {
        state.loading = false;
        state.errorState = false;
        state.errorMsg = "";
        state.actionTabData = action.payload;

      })
      .addCase(fetchActionPlanJobsData.rejected, (state, action) => {
        state.loading = false;
        state.errorState = true;
        state.errorMsg = action.error.message;
      })
  },
});

export default ActionPlanJobsDataSlice.reducer;