import { Route, Redirect } from "react-router-dom";
import React, { Suspense, lazy, Fragment } from "react";
import Loader from "react-loaders";

import { ToastContainer } from "react-toastify";

const HoneywellDashboards = lazy(() => import("../../DemoPages/Dashboards/"));


const AppMain = () => {

    return (
        <Fragment>
            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <div className="text-center">
                            <Loader type="ball-grid-cy"/>
                        </div>
                        <h6 className="mt-3">
                            Please wait while we load all the Dashboards Data
                        </h6>
                    </div>
                </div>
            }>
                <Route path="/dashboards/" component={HoneywellDashboards}/>
           

            <Route exact path="/" render={() => (
                <Redirect to="/dashboards/honeywell" />
            )}/>
             </Suspense>
            <ToastContainer/>
        </Fragment>
    )
};

export default AppMain;
