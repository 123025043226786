import { createSlice } from "@reduxjs/toolkit";

const initialFromDateString = new Date();

const initialState = {
    selectedFromDate: initialFromDateString,
};

const dateMonthSlice = createSlice({
    name: "date",
    initialState,
    reducers: {
        setMonthYearFromDate(state, action) {
            return {
                ...state,
                selectedFromDate: action.payload
            };
        }        
    }
});

export default dateMonthSlice.reducer;
export const { setMonthYearFromDate } = dateMonthSlice.actions;