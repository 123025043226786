import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Define the initial state for your slice
const initialState = {
  loading: false,
  errorState:false,
  errorMsg: "",
  hamiltonData: null,
  hamiltonZoneData:null,
};

export const fetchHamiltonGenericData = createAsyncThunk(
  'hamiltonGenericDataSlice/fetchHamiltonGenericData',
  async ({ authenticationToken, newstartDate, newendDate, accountID, referrer, assetId }) => {
    try {
      const response = await fetch(`https://${referrer}getJobsOfAccount`, {
        method: "POST",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${authenticationToken}`,
        },
        body: JSON.stringify({
          account_id: accountID,
          start_date: newstartDate,
          end_date: newendDate,
          asset_id: assetId,
        })
      });

      // if (!response.ok) {
      //   throw new Error('Network response was not ok');
      // }
      return response.json();
    } catch (error) {
        console.log(error)
      throw new Error('Error fetching analytics data: ' + error.message);
    }
  }
);

// Create a slice to manage the state
const hamiltonGenericDataSlice = createSlice({
  name: 'fetchHamiltonGenericData',
  initialState,
  reducers: {
   
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchHamiltonGenericData.pending, (state) => {
        state.loading = true;
        state.errorState = false;
        state.errorMsg = "";
      })
      .addCase(fetchHamiltonGenericData.fulfilled, (state, action) => {
        if (action.meta.arg.assetId === null || action.meta.arg.assetId) {
          let zoneDataFiltered =  action.meta.arg.assetId === null ? action.payload.filter((obj) => obj.asset_id === null) : action.payload;
          state.hamiltonZoneData = zoneDataFiltered; // Store in zone data if assetId exists
        } else {
          state.hamiltonData = action.payload; // Store in regular data otherwise
        }
        state.loading = false;
        state.errorState = false;
        state.errorMsg = "";
      })
      .addCase(fetchHamiltonGenericData.rejected, (state, action) => {
        state.loading = false;
        state.errorState = true;
        state.errorMsg = action.error.message;
      })
  },

});

export default hamiltonGenericDataSlice.reducer;