import { createSlice } from "@reduxjs/toolkit";

const initialToDateString = new Date();
const initialFromDateString = new Date();
initialFromDateString.setDate(initialFromDateString.getDate() - 3);

const initialState = {
    selectedFromDate: initialFromDateString,
    selectedToDate: initialToDateString,
    dateMsgDisplayed: "*D-3 days by default is displayed",
};

const dateSlice = createSlice({
    name: "date",
    initialState,
    reducers: {
        giveOutSelectedFromDate(state, action) {
            return {
                ...state,
                selectedFromDate: action.payload
            };
        },
        giveOutSelectedToDate(state, action) {
            return {
                ...state,
                selectedToDate: action.payload
            };
        },
        setDateMsgDisplayed(state, action) {
            return {
                ...state,
                dateMsgDisplayed: action.payload
            };
        },
    }
});

export default dateSlice.reducer;
export const { giveOutSelectedFromDate, giveOutSelectedToDate, setDateMsgDisplayed } = dateSlice.actions;