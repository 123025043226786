import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Define the initial state for your slice
const initialState = {
    loading: false,
    errorState:false,
    errorMsg: "",
    templatesList: null,
    refreshLibertyTemplates: false,
    loadingDownload: false,
    downloadData: null 
  };
  
  export const fetchAllLibertyTemplates = createAsyncThunk(
    'libertyTemplates/fetchAllLibertyTemplates',
    async ({ authenticationToken, referrer, keyword }) => {
      try {
        const response = await fetch(`https://${referrer}get-all-Liberty-template/${keyword}`, {
          method: "GET",
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            "Authorization": `Bearer ${authenticationToken}`,
          }                
        });
  
        // if (!response.ok) {
        //   throw new Error('Network response was not ok');
        // }
        return response.json();
      } catch (error) {
          console.log(error)
        throw new Error('Error fetching analytics data: ' + error.message);
      }
    }
);

export const setRefreshLibertyTemplates = (value) => {
    return {
      type: "libertyTemplates/setRefreshLibertyTemplates",
      payload: value,
    }
}

export const setDownloadParams = (value) => {
    return {
      type: "libertyTemplates/setDownloadParams",
      payload: value,
    }
}

export const downloadReportTemplate = createAsyncThunk(
    'libertyTemplates/downloadReportTemplate',
    async ({ authenticationToken, referrer, keyword, startYear, startMonth }) => {
      try {
        const response = await fetch(`https://${referrer}report/download-midnight-summary-data`, {
          method: "POST",
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            "Authorization": `Bearer ${authenticationToken}`,
          },
          body: JSON.stringify({
            year: startYear,
            month: startMonth,
            filter: keyword
          })                
        });
  
        // if (!response.ok) {
        //   throw new Error('Network response was not ok');
        // }
        return response.json();
      } catch (error) {
          console.log(error)
        throw new Error('Error fetching analytics data: ' + error.message);
      }
    }
);

export const downloadIdReportTemplate = createAsyncThunk(
  'libertyTemplates/downloadIdReportTemplate',
  async ({ authenticationToken, referrer, id, startYear, startMonth }) => {
    try {
      const response = await fetch(`https://${referrer}report/download-shift-data`, {
        method: "POST",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${authenticationToken}`,
        },
        body: JSON.stringify({
          year: startYear,
          month: startMonth,
          job_id: id
        })                
      });

      // if (!response.ok) {
      //   throw new Error('Network response was not ok');
      // }
      return response.json();
    } catch (error) {
        console.log(error)
      throw new Error('Error fetching analytics data: ' + error.message);
    }
  }
);
//TZ-1467
export const downloadPMMonthlyReport = createAsyncThunk(
  'libertyTemplates/downloadPMMonthlyReport',
  async ({ authenticationToken, referrer, startYear, startMonth }) => {
    try {
      const response = await fetch(`https://${referrer}report/download-monthly-pm-data`, {
        method: "POST",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${authenticationToken}`,
        },
        body: JSON.stringify({
          year: startYear,
          month: startMonth,
        })                
      });

      // if (!response.ok) {
      //   throw new Error('Network response was not ok');
      // }
      return response.json();
    } catch (error) {
        console.log(error)
      throw new Error('Error fetching analytics data: ' + error.message);
    }
  }
);

// Create a slice to manage the state
const libertyTemplates = createSlice({
    name: 'libertyTemplates',
    initialState,
    reducers: {
        // Reducer to handle additional actions related to incidents
        setRefreshLibertyTemplates: (state, action) => {
            state.refreshLibertyTemplates = action.payload;
            /* if (action.payload === false) {
              state.submitted = false;
            } */
        },
        setDownloadParams: (state, action) => {
            state.loadingDownload = false;
            state.downloadData = null;
            state.errorMsg = "";
            state.errorStatus = false;
        }
    },
    extraReducers: (builder) => {
      builder
        .addCase(fetchAllLibertyTemplates.pending, (state) => {
          state.loading = true;
          state.errorStatus = false;
          state.errorMsg = "";
        })
        .addCase(fetchAllLibertyTemplates.fulfilled, (state, action) => {
          state.loading = false;
          state.errorStatus = false;
          state.errorMsg = "";
          state.templatesList = action.payload;
        })
        .addCase(fetchAllLibertyTemplates.rejected, (state, action) => {
          state.loading = false;
          state.errorStatus = true;
          state.errorMsg = action.payload;
          state.templatesList = null;
        })    
        .addCase(downloadReportTemplate.pending, (state) => {
            state.loadingDownload = true;
            state.errorStatus = false;
            state.errorMsg = "";
        })
        .addCase(downloadReportTemplate.fulfilled, (state, action) => {
            state.loadingDownload = false;
            state.errorStatus = false;
            state.errorMsg = "";
            state.downloadData = action.payload;
        })
        .addCase(downloadReportTemplate.rejected, (state, action) => {
            state.loadingDownload = false;
            state.errorStatus = true;
            state.errorMsg = action.payload;
            state.downloadData = null;
        }) 
        .addCase(downloadIdReportTemplate.pending, (state) => {
          state.loadingDownload = true;
          state.errorStatus = false;
          state.errorMsg = "";
        })
        .addCase(downloadIdReportTemplate.fulfilled, (state, action) => {
            state.loadingDownload = false;
            state.errorStatus = false;
            state.errorMsg = "";
            state.downloadData = action.payload;
        })
        .addCase(downloadIdReportTemplate.rejected, (state, action) => {
            state.loadingDownload = false;
            state.errorStatus = true;
            state.errorMsg = action.payload;
            state.downloadData = null;
        })
        .addCase(downloadPMMonthlyReport.pending, (state) => {
          state.loadingDownload = true;
          state.errorStatus = false;
          state.errorMsg = "";
        })
        .addCase(downloadPMMonthlyReport.fulfilled, (state, action) => {
            state.loadingDownload = false;
            state.errorStatus = false;
            state.errorMsg = "";
            state.downloadData = action.payload;
        })
        .addCase(downloadPMMonthlyReport.rejected, (state, action) => {
            state.loadingDownload = false;
            state.errorStatus = true;
            state.errorMsg = action.payload;
            state.downloadData = null;
        })        
    },
});
  
// Export the reducer
export default libertyTemplates.reducer;