import { createSlice } from '@reduxjs/toolkit';

// Define the initial state for your slice
const initialState = {
    tabsContent: [
        {
            title: "Generic Report",
        },
        {
            title: "Room Report",
        },
        {
            title: "Incident Reports",
        },
        /* {
            title: "Smart Analytics",
        }, */
        {
            title: "Action Plans",
        },
        {
            title: "Open Incident",
        },
        {
            title: "Reports"
        },
        {
            title: "After Hour Report"
        }
    ],
};

export const setTabsVisibility = (value) => {
    return {
        type: 'tabVisibilityData/setTabs',
        payload: value
    };
};

export const addRoundTab = (value) => {
    return {
        type: 'tabVisibilityData/addRound',
        payload: value
    };
};

export const removeRoundTab = (value) => {
    return {
        type: 'tabVisibilityData/removeRound',
        payload: value
    };
};
export const addOpenIncidentTab = (value) => {
    return {
        type: 'tabVisibilityData/addOpenIncident',
        payload: value
    };
};

export const removeOpenIncidentTab = (value) => {
    return {
        type: 'tabVisibilityData/removeOpenIncident',
        payload: value
    };
};
export const addAddIncidentTab = (value) => {
    return {
        type: 'tabVisibilityData/addAddIncident',
        payload: value
    };
};

export const removeAddIncidentTab = (value) => {
    return {
        type: 'tabVisibilityData/removeAddIncident',
        payload: value
    };
};



// Create a slice to manage the state
const tabsDataSlice = createSlice({
    name: 'tabVisibilityData',
    initialState,
    reducers: {
        // Reducer to handle setting the tabs
        setTabs: (state, action) => {
            state.tabsContent = action.payload;
        },
        addRound: (state, action) => {
            // Check if the "Round Report" tab already exists
            const roundTabExists = state.tabsContent.some(tab => tab.title === "Round Report");
            // If it doesn't exist, add it
            if (!roundTabExists) {
                state.tabsContent.push({ title: "Round Report" });
            }
        },
        removeRound: (state, action) => {
            // Filter out the round tab from tabsContent
            state.tabsContent = state.tabsContent.filter(tab => tab.title !== "Round Report");
        },
        addOpenIncident: (state, action) => {
            // Check if the "Round Report" tab already exists
            let TabExists = state.tabsContent.some(tab => tab.title === "Open Incident");
            // If it doesn't exist, add it
            if (!TabExists) {
                state.tabsContent.push({ title: "Open Incident" });
            }
        },
        removeOpenIncident: (state, action) => {
            // Filter out the round tab from tabsContent
            state.tabsContent = state.tabsContent.filter(tab => tab.title !== "Open Incident");
        },
        addAddIncident: (state, action) => {
            // Check if the "Round Report" tab already exists
            const roundTabExists = state.tabsContent.some(tab => tab.title === "Add Incident");
            // If it doesn't exist, add it
            if (!roundTabExists) {
                state.tabsContent.push({ title: "Add Incident" });
            }
        },
        removeAddIncident: (state, action) => {
            // Filter out the round tab from tabsContent
            state.tabsContent = state.tabsContent.filter(tab => tab.title !== "Add Incident");
        },
    },
});

// Export the reducer
export default tabsDataSlice.reducer;