import { createSlice } from '@reduxjs/toolkit';

// Define the initial state for your slice
const initialState = {
  roomName: null,
  zoneNameValue: null,
  zoneNameLabel: null,
  zoneJobName:null,
  navZoneValue: null, //TZ-988
  navZoneLabel: null //TZ-988
};

export const setRoomName = (roomName) => {
  return {
    type: 'roomData/setRoomName',
    payload: roomName
  };
};

export const setZoneName = (zoneName) => {
  return {
    type: 'roomData/setZoneName',
    payload: zoneName
  };
};

//TZ-988
export const setZoneNamePageNav = (navZoneName) => {
  return {
    type: 'roomData/setZoneNamePageNav',
    payload: navZoneName
  }
}

// Create a slice to manage the state
const roomDataSlice = createSlice({
  name: 'roomData',
  initialState,
  reducers: {
    // Reducer to handle setting the room name
    setRoomName: (state, action) => {
      state.roomName = action.payload;
    },
    setZoneName: (state, action) => {
      state.zoneNameValue = action.payload.zoneNameValue;
      state.zoneNameLabel = action.payload.zoneNameLabel;
      state.zoneJobName = action.payload.zoneJobName;
    },
    setZoneNamePageNav: (state, action) => {
      /**TZ-988*/
      state.navZoneLabel = action.payload.navZoneLabel;
      state.navZoneValue = action.payload.navZoneValue;
    }
  },
 
});

// Export the reducer
export default roomDataSlice.reducer;